var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"customerDesktopList"}},[_c('v-data-table',{attrs:{"items-per-page":-1,"items":_vm.customers.data,"headers":_vm.headers,"loading":_vm.loading,"disable-sort":"","hide-default-footer":""},on:{"click:row":function($event){return _vm.$emit('select', $event.id)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('CustomerLabel',{attrs:{"customer":item,"size":"40px"}})]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.cpf(item.cpf) || "-")+" ")]}},{key:"item.phone_wa",fn:function(ref){
var item = ref.item;
return [(item.phone_wa)?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-whatsapp")]),_vm._v(" "+_vm._s(_vm.$format.phone(item.phone_wa))+" ")]:[_vm._v("-")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editCustomer', item.id)}}},[_vm._v(" mdi-pencil-outline ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }