<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <app-btn outlined v-bind="attrs" rounded v-on="on"> Opções </app-btn>
      </template>
      <v-list dense>
        <v-list-item @click="handleCustomerExport()">
          <v-list-item-avatar>
            <app-icon>download </app-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Exportar </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleCustomerImportSection()">
          <v-list-item-avatar>
            <app-icon>upload</app-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Importar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <CustomerImportSection ref="CustomerImportSection" />
  </div>
</template>

<script>
import CustomerImportSection from "@/components/customers/sections/CustomerImportSection.vue";
import { customerAlias } from "@/utils/customerAlias";

export default {
  components: {
    CustomerImportSection,
  },
  methods: {
    handleCustomerExport() {
      this.$http
        .download("customer/customers-export")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${customerAlias()}s.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCustomerImportSection() {
      this.$refs.CustomerImportSection.open();
    },
  },
};
</script>

<style>
</style>