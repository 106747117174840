<template>
  <div id="customerDesktopList">
    <v-data-table
      :items-per-page="-1"
      :items="customers.data"
      :headers="headers"
      :loading="loading"
      disable-sort
      hide-default-footer
      @click:row="$emit('select', $event.id)"
    >
      <template v-slot:[`item.name`]="{ item }">
        <CustomerLabel :customer="item" size="40px" />
      </template>
      <template v-slot:[`item.cpf`]="{ item }">
        {{ $format.cpf(item.cpf) || "-" }}
      </template>
      <template v-slot:[`item.phone_wa`]="{ item }">
        <template v-if="item.phone_wa">
          <v-icon class="mr-2" color="primary">mdi-whatsapp</v-icon>
          {{ $format.phone(item.phone_wa) }}
        </template>
        <template v-else>-</template>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <status-chip :value="item.status" />
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon @click.stop="$emit('editCustomer', item.id)">
          mdi-pencil-outline
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CustomerLabel from "@/components/customers/sections/CustomerLabel.vue";

export default {
  components: {
    CustomerLabel,
  },
  props: {
    loading: {},
    customers: {},
  },

  data() {
    return {
      headers: [
        { text: "Nome e Sobrenome", align: "start", value: "name" },
        { text: "CPF", align: "start", value: "cpf" },
        { text: "WhatsApp", align: "start", value: "phone_wa" },
        { text: "Status", align: "center", value: "status", width: "7%" },
        { text: "Ações", align: "center", value: "edit", width: "6%" },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="sass"  >
#customerDesktopList
  margin-right: -13px
  margin-left: -13px
  .v-data-table .v-data-table-header tr th
    font-family: 'Poppins'
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 18px
    color: #8C8A97
    border: none !important
    height: 18px
    padding-bottom: 10px
    // padding-left: 0px

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td
    font-family: 'Poppins'
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 21px
    padding-top: 8px
    padding-bottom: 8px
    // padding-left: 2px
    color: #5F5C6B
    border: none !important
</style>
