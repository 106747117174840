<template>
  <v-avatar :size="size" :color="color" v-if="name">
    <span class="white--text">{{ initials }}</span>
  </v-avatar>
</template>

<script>
import colors from "@/enums/colors";
export default {
  props: {
    name: {},
    size : {
      default : '35px'
    }
  },

  data: () => ({
    colors: colors,
  }),

  computed: {
    initials() {
      return this.name.slice(0, 1);
    },

    numberFromText() {
      const charCodes = this.name
        .split("")
        .map((char) => char.charCodeAt(0))
        .join("");
      return parseInt(charCodes, 10);
    },

    color() {
      return this.colors[this.numberFromText % this.colors.length];
    },
  },
};
</script>

<style>
</style>