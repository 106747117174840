<template>
  <v-dialog
    v-model="dialog"
    width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">Importar {{ customerAlias }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }">
              <v-card
                width="360"
                :elevation="hover ? 12 : 0"
                :class="{ 'on-hover': hover }"
                outlined
                to="/clientes/importar-via-planilha"
              >
                <v-card-text>
                  <div class="d-flex py-2">
                    <div>
                      <v-avatar color="#E6F2E6">
                        <v-icon color="success">mdi-file-excel</v-icon>
                      </v-avatar>
                    </div>
                    <div class="px-4">
                      <div>
                        <h2 class="font-weight-bold">
                          Importação por Planilha
                        </h2>
                        <br />
                        <span class="text-secondary"
                          >Faça a importação do seus {{ customerAlias }}s por
                          meio de uma planilha.</span
                        >
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }">
              <v-card
                width="360"
                :elevation="hover ? 12 : 0"
                :class="{ 'on-hover': hover }"
                :disabled="!supportContactsApi"
                outlined
                to="/clientes/importar"
              >
                <v-card-text>
                  <div class="d-flex py-2">
                    <div>
                      <v-avatar color="#EDEAFC">
                        <app-icon color="primary">contact_phone</app-icon>
                      </v-avatar>
                    </div>
                    <div class="px-4">
                      <div>
                        <h2 class="font-weight-bold">
                          Importação pela Agenda de Contatos
                        </h2>
                        <br />
                        <span class="text-secondary">
                          Realize a Importação do seus {{ customerAlias }}s por
                          meio da sua agenda de contatos.
                        </span>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      customerAlias: customerAlias(),

      dialog: false,
    };
  },
  computed: {
    supportContactsApi() {
      return "contacts" in navigator && "ContactsManager" in window;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>