<template>
  <div class="d-flex align-center">
    <div class="mr-4">
      <CustomerAvatar :name="customer.name" :size="size" />
    </div>
    <div>
      {{ customer.name }}
      <!-- <br />
                <small class="text--secondary">Código: #{{ item.code }}</small> -->
    </div>
  </div>
</template>

<script>
import CustomerAvatar from "./CustomerAvatar.vue";

export default {
  components: {
    CustomerAvatar,
  },
  props: {
    customer: {},
    size: {
      default: "35px",
    },
  },
};
</script>

<style>
</style>