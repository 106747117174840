<template>
  <div>
    <app-btn
      v-on="$listeners"
      class="ml-auto"
      color="primary"
      v-if="!$vuetify.breakpoint.mobile"
      rounded
    >
      <app-icon left>add</app-icon>
      <span>Novo</span>
    </app-btn>

    <v-btn v-on="$listeners" fab small elevation="0" color="primary" v-else>
      <app-icon>add</app-icon>
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>