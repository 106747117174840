<template>
  <div>
    <portal to="mobileLeftAppBar">
      <div class="d-flex align-center">
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-4" v-bind="attrs" fab small text v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="handleCustomerExport()">
                <v-list-item-avatar>
                  <app-icon>download </app-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Exportar </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleCustomerImportSection()">
                <v-list-item-avatar>
                  <app-icon>upload</app-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Importar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-btn
          color="primary"
          fab
          small
          elevation="0"
          @click="handleStoreCustomer()"
        >
          <app-icon>add</app-icon>
        </v-btn>
      </div>
    </portal>
    <v-row class="d-flex align-center">
      <v-col cols="12" md="4">
        <app-text-search-field
          @filter="showFilter = !showFilter"
          :isLoading="loading"
          @input="handleSearchInput($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-chip-group
          @change="select()"
          mandatory
          v-model="searchParams.status"
        >
          <v-chip color="primary" filter :value="'active'"> Ativos </v-chip>
          <v-chip outlined filter :value="'inactive'"> Inativos </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="showFilter">
      <v-col>
        <GroupSelect
          v-model="searchParams.group_id"
          @input="select()"
          :item-value="(item) => item.id"
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between align-center">
        <small class="text--secondary pl-2">
          {{ customers.total }} registros encontrado{{ plural }}
        </small>

        <v-icon @click="showMoreInfo = !showMoreInfo">
          {{ showMoreInfo ? "mdi-view-list" : "mdi-format-list-text" }}
        </v-icon>
      </v-col>
    </v-row>

    <template v-if="boot">
      <template v-for="n in 11">
        <v-skeleton-loader type="list-item-avatar" />
      </template>
    </template>
    <template v-if="!boot">
      <app-pagination
        @click="select($event)"
        :data="customers"
        :showCount="false"
      >
        <div id="customerMobileList">
          <v-list :three-line="showMoreInfo">
            <v-list-item-group>
              <template v-for="(item, index) in customers.data">
                <v-list-item class="px-2" :key="index">
                  <div
                    style="cursor: pointer"
                    class="d-flex align-center flex-grow-1 py-2"
                    @click="handleCustomerProfile(item.id)"
                  >
                    <CustomerAvatar size="40px" :name="item.name" />
                    <div class="ml-4">
                      <strong v-text="item.name" />
                      <div
                        v-if="showMoreInfo"
                        class="d-flex flex-column text--secondary"
                      >
                        <span v-text="$format.cpf(item.cpf)" />
                        <span v-text="$format.phone(item.phone_wa)" />
                      </div>
                    </div>
                    <div class="ml-auto" style="margin-right: -10px !important">
                      <app-icon style="font-size: 32px" color="primary">
                        chevron_right
                      </app-icon>
                    </div>
                  </div>
                </v-list-item>
                <v-divider :key="'divider' + item.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </app-pagination>
    </template>
    <CustomerFormDialog
      ref="CustomerFormDialog"
      @store="select(searchParams.page)"
    />
    <CustomerImportSection ref="CustomerImportSection" />
  </div>
</template>

<script>
import CustomerFormDialog from "@/components/customers/forms/CustomerFormDialog.vue";
import CustomerAvatar from "@/components/customers/sections/CustomerAvatar.vue";

import CustomerImportSection from "@/components/customers/sections/CustomerImportSection.vue";
import { customerAlias } from "@/utils/customerAlias";
import GroupSelect from "@/components/group/ui/GroupSelect";

import CustomersMixin from "@/mixins/Customer/CustomersMixin";

export default {
  mixins: [CustomersMixin],

  components: {
    CustomerImportSection,
    CustomerFormDialog,
    GroupSelect,

    CustomerAvatar,
  },

  data() {
    return {
      showMoreInfo: localStorage.getItem("customer-show-more-info") == "true",
      showFilter: false,
    };
  },

  computed: {
    plural() {
      if (this.customers.total > 1) {
        return "s";
      }

      return "";
    },
  },

  watch: {
    showMoreInfo: {
      handler() {
        localStorage.setItem("customer-show-more-info", this.showMoreInfo);
      },
      immediate: true,
    },
  },

  methods: {
    handleCustomerExport() {
      this.$http
        .download("customer/customers-export")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${customerAlias()}s.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCustomerImportSection() {
      this.$refs.CustomerImportSection.open();
    },
  },
};
</script>

<style lang="sass" >
#customerMobileList
  *
    font-size: 14px
</style>

