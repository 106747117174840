<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <app-title> {{ customerAlias }}s </app-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <app-text-search-field
              @filter="showFilter = !showFilter"
              :isLoading="loading"
              @input="handleSearchInput($event)"
            />
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="align-center d-flex justify-space-between"
          >
            <div>
              <v-chip-group
                @change="select()"
                mandatory
                v-model="searchParams.status"
              >
                <v-chip color="primary" filter :value="'active'">
                  Ativos
                </v-chip>
                <v-chip outlined filter :value="'inactive'"> Inativos </v-chip>
              </v-chip-group>
            </div>
            <div
              class="d-md-flex d-none pr-2"
              v-if="$acl.can('can_store_customers')"
            >
              <div><ExportImportCustomerBtn rounded /></div>
              <div class="ml-4">
                <AddCustomerBtn
                  rounded
                  v-if="$acl.can('can_store_customers')"
                  @click="handleStoreCustomer((id = null))"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center" v-if="showFilter">
          <v-col cols="3">
            <GroupSelect
              v-model="searchParams.group_id"
              @input="select()"
              :item-value="(item) => item.id"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <template v-if="boot">
              <template v-for="n in 11">
                <v-skeleton-loader type="list-item-avatar" />
              </template>
            </template>
            <template v-if="!boot">
              <app-pagination
                class=""
                @click="select($event)"
                :data="customers"
              >
                <div class="mt-7">
                  <DesktopCustomersList
                    @select="handleCustomerProfile($event)"
                    @editCustomer="handleStoreCustomer($event)"
                    :customers="customers"
                  />
                </div>
              </app-pagination>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <CustomerFormDialog
      ref="CustomerFormDialog"
      @store="select(searchParams.page)"
    />
  </div>
</template>

<script>
import DesktopCustomersList from "@/components/customers/lists/new/DesktopCustomersList.vue";
import ExportImportCustomerBtn from "@/components/customers/ui/CustomerExportImportBtn.vue";
import AddCustomerBtn from "@/components/customers/ui/AddCustomerBtn.vue";
import CustomerFormDialog from "@/components/customers/forms/CustomerFormDialog.vue";
import GroupSelect from "@/components/group/ui/GroupSelect";

import CustomersMixin from "@/mixins/Customer/CustomersMixin";
export default {
  mixins: [CustomersMixin],

  components: {
    DesktopCustomersList,
    ExportImportCustomerBtn,
    AddCustomerBtn,
    CustomerFormDialog,
    GroupSelect,
  },

  data() {
    return {
      showFilter: false,
    };
  },
};
</script>

<style>
</style>