<template>
  <div>
    <template v-if="hasCustomer">
      <template v-if="!isMobile">
        <CustomersDesktop/>
      </template>
      <template v-if="isMobile">
        <CustomersMobile/>
      </template>
    </template>
    <template v-if="!hasCustomer">
      <v-card outlined style="border-radius: 16px">
        <v-card-text>
          <div
            class="d-flex justify-space-between flex-column flex-md-row align-center"
          >
            <div>
              <strong style="font-seize: 18px" class="mb-2">
                Cadastre seu primeiro {{ customerAlias }}!
              </strong>
              <div class="flex-row mt-4" style="max-width: 543px">
                <span style="font-seize: 14px">
                  O primeiro passo é ter seu {{ customerAlias }} cadastrado,
                  tendo acesso ao seu prontuário, anamnese, documentos e mais!
                </span>
              </div>
              <div class="d-md-block d-flex my-4">
                <v-btn
                  rounded
                  color="primary"
                  class="mr-6"
                  @click="handleStoreCustomer((id = null))"
                >
                  Cadastrar novo
                </v-btn>
                <v-btn
                  rounded
                  color="disabled"
                  outlined
                  @click="handleCustomerImportSection()"
                >
                  Importar <v-icon right>mdi-upload</v-icon>
                </v-btn>
              </div>
            </div>
            <div>
              <v-img
                width="333px"
                height="155.6px"
                src="@/assets/images/customers/img-01.svg"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <CustomerFormDialog
      ref="CustomerFormDialog"
      @store="select(searchParams.page)"
    />
    <CustomerImportSection ref="CustomerImportSection" />
  </div>
</template>
<script>
import CustomersDesktop from "@/components/customers/views/CustomersDesktop.vue";
import CustomersMobile from "@/components/customers/views/CustomersMobile.vue";
import CustomerImportSection from "@/components/customers/sections/CustomerImportSection.vue";

import CustomersMixin from "@/mixins/Customer/CustomersMixin";
import CustomerFormDialog from "@/components/customers/forms/CustomerFormDialog.vue";

export default {
  mixins: [CustomersMixin],

  components: {
    CustomersDesktop,
    CustomersMobile,
    CustomerFormDialog,
    CustomerImportSection,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style></style>
