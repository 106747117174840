
export default [
  "red",
  "red darken-2",
  "pink",
  "pink darken-2",
  "purple",
  "purple darken-2",
  "deep-purple",
  "deep-purple darken-2",
  "indigo",
  "indigo darken-2",
  "blue",
  "blue darken-2",
  "light-blue",
  "light-blue darken-2",
  "cyan",
  "cyan darken-2",
  "teal",
  "teal darken-2",
  "green",
  "green darken-2",
  "light-green",
  "light-green darken-2",
  "lime",
  "lime darken-2",
  "yellow",
  "yellow darken-2",
  "amber",
  "amber darken-2",
  "orange",
  "orange darken-2",
  "deep-orange",
  "deep-orange darken-2",
  "brown",
  "brown darken-2",
  "blue-grey",
  "blue-grey darken-2",
  "grey",
  "grey darken-2",
]

