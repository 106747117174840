import { customerAlias } from "@/utils/customerAlias";

export default {
  data: () => ({
    loading: false,
    boot: false,
    searchParams: {
      orderBy: "name",
      orderDirection: "asc",
      status: "active",
    },

    customers: {
      data: [],
    },
  }),

  created() {

    this.$nextTick(async () => {
      if (this.$store.state.app.lastRoute.name !== "customeProfile") {
        this.page = 1;
      }
      this.boot = true;
      await this.select();
    });
  },

  computed: {
    page: {
      get() {
        return this.$store.state.customer.searchParams.page;
      },
      set(value) {
        this.$store.commit("customer/setSearchParams", value);
      },
    },

    company() {
      return this.$store.state.auth.company;
    },

    customerAlias() {
      return customerAlias();
    },

    hasCustomer() {
      return this.$store.state.onboarding.hasCustomer > 0;
    },
  },

  methods: {
    storeSearchParams(newVal) {
      this.$store.commit("customer/setSearchParams", newVal);
    },

    async select(page = 1) {
      this.loading = true;

      this.searchParams.page = page;

      await this.$http
        .index("customer/customer", this.searchParams)
        .then((response) => {
          this.customers = response.customers;
          this.boot = false;
        });

      this.loading = false;
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleStoreCustomer(id = null) {
      this.$refs.CustomerFormDialog.open(id);
    },

    handleCustomerProfile(id) {
      this.$router.push("/cliente/" + id);
    },
    handleCustomerImportSection() {
      this.$refs.CustomerImportSection.open();
    },
  },
};